import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "why-it-matter"
    }}>{`Why it matter`}</h2>
    <p>{`The size and the shape of fermentor will affect the falvors of beer. It is possible for different
fermentors to create the beer of same flavor, but it usually takes alternations in fermentation
procedure, and some times even in wort production.`}</p>
    <p>{`For example, if you ferment 10 hectorliter batch at 21 `}{`°`}{`C, you may need 20 liter version at 19 `}{`°`}{`C
to get the same ester profile. Fermentor characteristics such as hydrostatic pressure, the saturation
point of certain gasses, temperature gradients, and dead spots all make it necessary to use a lower temperature
in a plastic bucket versus a large conical fermentor.`}{`[1]`}</p>
    <h2 {...{
      "id": "homebrew-fermentors"
    }}>{`Homebrew Fermentors`}</h2>
    <ul>
      <li parentName="ul">{`plastic is prone to contamination is because it is soft and it is easy to be scratched, bacteria
may live in the scratch.`}</li>
      <li parentName="ul">{`glass easy to be broken.`}</li>
      <li parentName="ul">{`kegs, to narrow which may affect the fermentation process, also less headspace.`}</li>
    </ul>
    <h2 {...{
      "id": "commercial-fermentors"
    }}>{`Commercial Fermentors`}</h2>
    <h3 {...{
      "id": "cylindroconical-fermentor"
    }}>{`Cylindroconical fermentor`}</h3>
    <ul>
      <li parentName="ul">{`The more vertical the fermentor, the fast the fermentation.`}</li>
      <li parentName="ul">{`conditioning and fermentation could be in a same place.(not common in brewery nowadays)`}</li>
    </ul>
    <h2 {...{
      "id": "cautions"
    }}>{`Cautions`}</h2>
    <ul>
      <li parentName="ul">{`Running test batches with any new fermentor.`}</li>
    </ul>
    <p>{`References:
`}{`[1]`}{` p84 Yeast`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      